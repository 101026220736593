/* eslint-disable import/prefer-default-export */
import { useFocusRing } from "@react-aria/focus";
import { useHover } from "@react-aria/interactions";
import { useLink } from "@react-aria/link";
import { mergeProps } from "@react-aria/utils";
import clsx from "clsx";
import { ForwardedRef, forwardRef, ReactElement } from "react";

import { atlasLinkExternal } from "../../../icons";
import {
  getPropsWithDefaults,
  useOptionalRef,
} from "../../__utils/__deprecated";
import {
  makeElementClassNameFactory,
  makeRootClassName,
} from "../../__utils/atlas";
import { Icon } from "../../icon";
import { AtlasDeprecatedLinkProps } from "./types";

// config
// ------

const ROOT = makeRootClassName("DeprecatedLink");
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {
  isDisabled: false,
  isExternal: false,
  variant: "default",
} as const;

const EXTERNAL_ICON = atlasLinkExternal;

// component
// ---------

function LinkComponent(
  props: AtlasDeprecatedLinkProps,
  ref: ForwardedRef<HTMLAnchorElement>
): ReactElement {
  const p = getPropsWithDefaults(props, DEFAULT_PROPS);
  const domRef = useOptionalRef(ref);

  // behavior
  // --------

  const { linkProps, isPressed } = useLink({ ...p, elementType: "a" }, domRef);
  const { hoverProps, isHovered } = useHover({ isDisabled: p.isDisabled });
  const { focusProps, isFocusVisible } = useFocusRing();
  const behaviorProps = mergeProps(linkProps, hoverProps, focusProps);

  // rendering
  // ---------

  return (
    <a
      ref={domRef}
      href={p.href}
      // by default, external links are opened on a new tab
      target={p.target ?? ((p.isExternal && "_blank") || undefined)}
      // by default, no referrer nor opener is made available, for security purposes
      rel={p.rel ?? "noreferrer"}
      {...behaviorProps}
      className={clsx([
        `${ROOT} variant-${p.variant}`,
        {
          "is-disabled": p.isDisabled,
          "is-hovered": isHovered,
          "is-focus-visible": isFocusVisible,
          "is-pressed": isPressed,
        },
        p.className,
      ])}
    >
      <span className={el`label`}>{p.children}</span>
      {p.isExternal && <Icon className={el`icon`} content={EXTERNAL_ICON} />}
    </a>
  );
}

/**
 * Links allow users to navigate to other pages, internal or external to the current website or app.
 * @deprecated Use `Link` instead.
 */
export const DeprecatedLink = forwardRef<
  HTMLAnchorElement,
  AtlasDeprecatedLinkProps
>(LinkComponent);
