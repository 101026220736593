import { atlasLogoOffice365Color, serviceGoogle } from "@resource/atlas/icons";
import { Auth0Connection } from "@resource/common";
import loginWithPopUp from "auth/loginWithPopUp";
import _ from "lodash";
import { useRouter } from "next/router";
import { useState } from "react";
import inIframe, { useInV2Frame } from "utils/in-iframe";

import LoginButton from "./LoginButton";

interface LoginFormProps {
  signup?: boolean;
  onLogin?: () => void;
}

function LoginForm({ onLogin, signup = false }: LoginFormProps) {
  const router = useRouter();
  const isWithinV2 = useInV2Frame();
  const [loading, setLoading] = useState<Auth0Connection | null>(null);

  const onClickLogin = async (connection: Auth0Connection) => {
    setLoading(connection);
    const params = {
      ..._.pickBy(router.query),
      connection,
      ...(connection === "guides-waad"
        ? { scope: "https://graph.microsoft.com/User.Read" }
        : {}),
    };
    if (inIframe()) {
      loginWithPopUp(params, () => {
        setLoading(null);
        onLogin?.();
      });
    } else {
      const encodedParams = new URLSearchParams(params).toString();
      window.location.assign(`/api/auth/login?${encodedParams}`);
    }
  };
  return (
    <>
      <LoginButton
        connection={Auth0Connection.google}
        onClick={onClickLogin}
        label={signup ? "Continue with Google" : "Sign in with Google"}
        loading={loading}
        icon={serviceGoogle}
      />
      {isWithinV2 ? null : (
        <LoginButton
          connection={Auth0Connection.outlook}
          onClick={onClickLogin}
          label={
            signup ? "Continue with Office 365" : "Sign in with Office 365"
          }
          loading={loading}
          icon={atlasLogoOffice365Color}
        />
      )}
    </>
  );
}

export default LoginForm;
