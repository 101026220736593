import { AtlasIconData, Button } from "@resource/atlas";
import { Auth0Connection } from "@resource/common";

interface LoginButtonProps {
  connection: Auth0Connection;
  label: string;
  loading: Auth0Connection | null;
  onClick(connection: Auth0Connection): void;
  icon?: AtlasIconData;
}

function LoginButton({
  connection,
  icon,
  label,
  loading,
  onClick,
}: LoginButtonProps) {
  const isLoading = loading === connection;
  return (
    <Button
      className="w-full"
      isLoading={isLoading}
      onClick={() => onClick(connection)}
      icon={icon}
    >
      {label}
    </Button>
  );
}

export default LoginButton;
