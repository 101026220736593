import { Auth0Connection } from "@resource/common";
import _ from "lodash";

const slackLookup = "oauth2|slack";
export const CONNECTION_LOOKUP = {
  "google-oauth2": Auth0Connection.google,
  waad: Auth0Connection.outlook,
  [slackLookup]: Auth0Connection.slack,
} as const;
export type ValidProviders = keyof typeof CONNECTION_LOOKUP;

export const O365SendScope =
  "https://outlook.office365.com/EAS.AccessAsUser.All https://outlook.office365.com/EWS.AccessAsUser.All";

const CONNECTION_SCOPES = {
  [Auth0Connection.google]: "https://www.googleapis.com/auth/gmail.send",
  [Auth0Connection.outlook]: `offline_access ${O365SendScope}`,
  [Auth0Connection.slack]: "",
} as const;

export const getProvider = (auth0UserId: string): ValidProviders => {
  if (auth0UserId.startsWith(slackLookup)) {
    return slackLookup;
  }

  const provider = _.split(auth0UserId, "|")[0];
  if (provider !== "google-oauth2" && provider !== "waad") {
    throw new Error(`Unsupported provider string ${provider}`);
  }
  return provider;
};

export const getScopes = (connection: Auth0Connection): string =>
  CONNECTION_SCOPES[connection];

export const getConnection = (provider: ValidProviders): Auth0Connection =>
  CONNECTION_LOOKUP[provider];
